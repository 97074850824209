import React from 'react';

const LinkedIn = (props) => {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.9997 19.9997H17.1375V12.969C17.1375 11.1287 16.3769 9.87242 14.7044 9.87242C13.4251 9.87242 12.7137 10.7202 12.3826 11.5373C12.2584 11.8305 12.2778 12.239 12.2778 12.6476V19.9997H7.46082C7.46082 19.9997 7.52291 7.54549 7.46082 6.4134H12.2778V8.54567C12.5624 7.61336 14.1016 6.28278 16.558 6.28278C19.6055 6.28278 21.9997 8.23704 21.9997 12.4452V19.9997ZM2.58958 4.71405H2.55854C1.00634 4.71405 0 3.67545 0 2.35894C0 1.01683 1.03609 0 2.61933 0C4.20128 0 5.17399 1.01427 5.20503 2.3551C5.20503 3.6716 4.20128 4.71405 2.58958 4.71405ZM0.555008 6.41347H4.84295V19.9998H0.555008V6.41347Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default LinkedIn;
