import React from 'react';

const Facebook = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6826 20H1.10385C0.49403 20 0 19.5057 0 18.8961V1.10384C0 0.494104 0.494108 0 1.10385 0H18.8962C19.5058 0 20 0.494104 20 1.10384V18.8961C20 19.5057 19.5057 20 18.8962 20H13.7997V12.2549H16.3994L16.7886 9.23649H13.7997V7.30943C13.7997 6.43554 14.0424 5.84 15.2955 5.84L16.8939 5.83931V3.13962C16.6175 3.10284 15.6687 3.02065 14.5648 3.02065C12.2603 3.02065 10.6826 4.4273 10.6826 7.0105V9.23649H8.0763V12.2549H10.6826V20Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Facebook;
