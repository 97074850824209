import React from 'react';
import {
  makeStyles,
  AppBar,
  ListItem,
  List,
} from '@material-ui/core';
import Logo from '../../svg/Logo';
import Container from '../../shared/Container';
import { Link } from 'gatsby-plugin-intl';
import NavItem from './NavItem';
import useGetCurrentPath from '../../../hooks/useGetCurrentPath';
import styles from './NavBar.styles';
import useGetNavigationTranslation from '../../../hooks/useGetNavigationTranslation';

const useStyles = makeStyles(styles);

const NavBar = () => {
  const navItems = useGetNavigationTranslation();
  const { isCurrentPathLight } = useGetCurrentPath();
  const classes = useStyles({
    isCurrentPathLight,
  });

  return (
    <Container className={classes.navBar}>
      <AppBar color="default" className={classes.appBar}>
        <Link to="/" className={classes.logo}>
          <Logo width="4.5em" />
        </Link>
        <List disablePadding className={classes.listItems}>
          {navItems.map(({ pathname, path }) => {
            return (
              <ListItem
                key={path}
                className={classes.listItem}
              >
                <NavItem path={path} pageName={pathname} />
              </ListItem>
            );
          })}
        </List>
      </AppBar>
    </Container>
  );
};

export default NavBar;
