import React from 'react';

const Youtube = (props) => {
  return (
    <svg
      width="29"
      height="20"
      viewBox="0 0 29 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2021 13.6884L11.2013 5.69886L18.8301 9.70739L11.2021 13.6884ZM27.9529 4.31421C27.9529 4.31421 27.6768 2.35482 26.8306 1.49198C25.7568 0.358999 24.5532 0.353668 24.0018 0.28791C20.0506 0 14.1238 0 14.1238 0H14.1115C14.1115 0 8.18471 0 4.23353 0.28791C3.68118 0.353668 2.47853 0.358999 1.40382 1.49198C0.557647 2.35482 0.282353 4.31421 0.282353 4.31421C0.282353 4.31421 0 6.61572 0 8.91634V11.0739C0 13.3754 0.282353 15.676 0.282353 15.676C0.282353 15.676 0.557647 17.6354 1.40382 18.4982C2.47853 19.6312 3.88941 19.5957 4.51765 19.7139C6.77647 19.9325 14.1176 20 14.1176 20C14.1176 20 20.0506 19.9911 24.0018 19.7032C24.5532 19.6366 25.7568 19.6312 26.8306 18.4982C27.6768 17.6354 27.9529 15.676 27.9529 15.676C27.9529 15.676 28.2353 13.3754 28.2353 11.0739V8.91634C28.2353 6.61572 27.9529 4.31421 27.9529 4.31421Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Youtube;
