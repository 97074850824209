import React from 'react';
import NavBar from './desktop/NavBar';
import Drawer from './mobile/Drawer';

const Index = () => {
  return (
    <>
      <NavBar />
      <Drawer />
    </>
  );
};

export default Index;
