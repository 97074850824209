export default ({
  palette: { primary, text },
  fonts,
  breakpoints: { down, up, only },
  spacing,
}) => ({
  Footer: {
    backgroundColor: primary.dark,
    position: 'relative',
    zIndex: 2,
    color: text.secondary,
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
    [down('xs')]: {
      fontSize: fonts.size.xxs,
    },
  },
  container: {
    paddingTop: spacing(10),
    paddingBottom: spacing(10),
    '&>*': {
      marginBottom: spacing(10),
      wordBreak: 'word-break',
      '&:last-child': {
        marginBottom: spacing(0),
      },
      [down('xs')]: {
        marginBottom: spacing(1),
      },
    },
  },
  logo: {
    '& svg': {
      fontSize: 29,
    },
    '& a': {
      color: 'inherit',
    },
  },
  section: {
    opacity: 0.6,
    lineHeight: '26px',
    display: 'flex',
    '&>div>:nth-child(1)': { marginBottom: spacing(4) },
    '& a': {
      color: 'inherit',
      display: 'block',
      fontSize: fonts.size.xs,
      textDecoration: 'none',
      [down('xs')]: {
        fontSize: fonts.size.xxs,
      },
    },
    [up('md')]: {
      '&:after': {
        content: 'close-quote',
        width: 1,
        height: '90%',
        opacity: 0.2,
        alignSelf: 'center',
        backgroundColor: text.secondary,
        display: 'inline-block',
      },
      '&:nth-child(3n):after': {
        display: 'none',
      },
      '&:nth-child(n+2)>div': {
        width: 'fit-content',
        margin: '0 auto',
      },
    },
    [only('sm')]: {
      padding: spacing(4),
      paddingLeft: spacing(0),
    },
    [down('xs')]: {
      paddingTop: spacing(5),
      flexFlow: 'column',
      '&:after': {
        content: 'close-quote',
        opacity: 0.2,
        display: 'block',
        width: '100%',
        marginTop: spacing(5),
        height: 1,
        borderBottom: `1px solid ${text.secondary}`,
      },
    },
  },
  address: {
    whiteSpace: 'pre-line',
  },
  text: {
    fontWeight: 400,
    [down('xs')]: {
      fontSize: fonts.size.xxs,
    },
  },
  title: {
    fontWeight: 500,
    [down('xs')]: {
      fontSize: fonts.size.xxs,
    },
  },
  copyRights: {
    opacity: '0.2 !important',
    textAlign: 'center',
    fontWeight: 500,
    fontSize: fonts.size.xxs,
    [down('xs')]: {
      fontSize: fonts.size.xxxxs,
    },
  },
});
