import { Link } from '@material-ui/core';
import React from 'react';

const ExternalLink = ({ children, ...props }) => {
  return (
    <Link
      target="_blank"
      rel="noreferrer"
      underline="always"
      {...props}
    >
      {children}
    </Link>
  );
};

export default ExternalLink;
