import {
  Grid,
  useMediaQuery,
  useTheme,
  makeStyles,
  ButtonBase,
  Typography,
} from '@material-ui/core';
import React from 'react';
import Logo from '../../../svg/Logo';
import SocialMediaLinks from '../../SocialMediaLinks';
import ExternalLink from '../../ExternalLink';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Container from '../../Container';
import { FormattedMessage } from 'gatsby-plugin-intl';
import styles from './index.styles';

const useStyles = makeStyles(styles);

const Footer = () => {
  const {
    name,
    address,
    email,
  } = useStaticQuery(query).site.siteMetadata.companyInfo;

  const footerSections = [
    {
      title: 'Footer.sections.0.title',
      items: [
        {
          item: 'Footer.sections.0.items.0',
          path: '/process',
        },
        {
          item: 'Footer.sections.0.items.1',
          path: '/news',
        },
        {
          item: 'Footer.sections.0.items.2',
          path: '/startups',
        },
        {
          item: 'Footer.sections.0.items.3',
          path: '/apply',
        },
        {
          item: 'Footer.sections.0.items.4',
          path: '/contact',
        },
      ],
    },
    {
      title: 'Footer.sections.1.title',
      items: [
        {
          item: 'Footer.sections.1.items.0',
          path: '/cookie-policy',
        },
        {
          item: 'Footer.sections.1.items.1',
          path: '/privacy-policy',
        },
        {
          item: 'Footer.sections.1.items.2',
          path: '/terms-of-service',
        },
      ],
    },
  ];

  const classes = useStyles();
  const theme = useTheme();
  const smallScreen = useMediaQuery(
    theme.breakpoints.down('xs'),
  );

  return (
    <Grid container className={classes.Footer}>
      <Container className={classes.container}>
        <Grid container justify='space-between'>
          <Grid item className={classes.logo}>
            <ButtonBase>
              <Link to='/'>
                <Logo />
              </Link>
            </ButtonBase>
          </Grid>
          {!smallScreen && <SocialMediaLinks />}
        </Grid>
        <Grid container>
          {footerSections.map(({ title, items }) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              className={classes.section}
              key={title}
            >
              <Grid container item direction='column'>
                <Grid item>
                  <Typography
                    variant='subtitle2'
                    component='p'
                    className={classes.title}
                  >
                    <FormattedMessage id={title} />
                  </Typography>
                </Grid>
                {items.map(({ item, path }) => (
                  <Grid item key={item}>
                    <Link to={path}>
                      <FormattedMessage id={item} />
                    </Link>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          ))}
          <Grid
            item
            xs={12}
            md={4}
            className={classes.section}
          >
            <Grid container direction='column'>
              <Grid item>
                <Typography
                  variant='subtitle2'
                  component='p'
                  className={classes.title}
                >
                  {name}
                </Typography>
              </Grid>
              <Grid item>
                <ExternalLink
                  href={`https://www.google.se/maps/search/${address}`}
                  className={classes.address}
                  underline='none'
                >
                  {address}
                </ExternalLink>
                <ExternalLink href={`mailto:${email}`}>
                  {email}
                </ExternalLink>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justify='center'>
          {smallScreen && <SocialMediaLinks />}
          <Typography
            component='p'
            className={classes.copyRights}
          >
            &copy; Copyright 2020 – {name} – All rights
            reserved
          </Typography>
        </Grid>
      </Container>
    </Grid>
  );
};

export default Footer;

const query = graphql`
  query Footer {
    site {
      siteMetadata {
        companyInfo {
          address
          email
          name
        }
      }
    }
  }
`;
