import patterns from '../../../constants/patterns';

export default ({
  palette: { primary, text },
  spacing,
  fonts,
  breakpoints: { up },
}) => ({
  appBar: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    padding: spacing(1, 2.5),
    transition: 'color .3s',
    color: ({ isCurrentPathLight }) =>
      isCurrentPathLight ? text.secondary : text.primary,
    [up(750)]: {
      display: 'none',
    },
  },
  toolBar: {
    padding: spacing(0),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  openButton: {
    padding: spacing(1),
  },
  logo: {
    fontSize: fonts.size.m,
    padding: spacing(1),
    transition: 'color .3s',
    color: ({ isCurrentPathLight, menuOpen }) =>
      isCurrentPathLight && !menuOpen
        ? text.secondary
        : text.primary,
    display: 'flex',
    alignItems: 'center',
  },
  drawer: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: 'calc(100vh - calc(100vh - 100%))',
    width: '100vw',
    backgroundColor: primary.dark,
    zIndex: 5,
  },
  list: {
    position: 'relative',
    zIndex: 5,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  listItem: {
    width: 'auto',
    padding: spacing(0),
    marginTop: spacing(6),
    '&:first-child': {
      marginTop: spacing(0),
    },
    '& > a': {
      color: text.secondary,
      textDecoration: 'none',
      fontSize: fonts.size.xxm,
      '&:hover': {
        textDecoration: 'none',
      },
    },
  },
  bottomPattern: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    height: '50vh',
    width: '100vw',
    opacity: 0.2,
    overflow: 'hidden',
    '&::before': {
      position: 'absolute',
      bottom: 0,
      left: 0,
      content: '""',
      transform: 'rotate(90deg)',
      minHeight: '100%',
      width: '50vh',
      maxHeight: '100vw',
      backgroundImage: `url(${patterns['archLight']})`,
      backgroundPositionX: -25,
      backgroundPositionY: -20,
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      height: '50vh',
      width: '100vw',
      overflow: 'hidden',
      background: `radial-gradient(92.15% 106.48% at 92.15% -3.59%, rgba(29, 28, 34, 0.04) 0%, ${primary.dark} 100%)`,
      transform: 'rotate(180deg)',
    },
  },
});
