import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  IconButton,
  Grid,
  AppBar,
  Toolbar,
  Fade,
  ListItem,
  List,
  Slide,
} from '@material-ui/core';
import Logo from '../../svg/Logo';
import Burger from '../../svg/Burger';
import useGetCurrentPath from '../../../hooks/useGetCurrentPath';
import { preventBodyScroll } from '../../../utils/functions';
import { Link } from 'gatsby-plugin-intl';
import NavItem from './NavItem';
import styles from './Drawer.styles';
import useGetNavigationTranslation from '../../../hooks/useGetNavigationTranslation';

const useStyles = makeStyles(styles);

const Drawer = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { isCurrentPathLight } = useGetCurrentPath();
  const navItems = useGetNavigationTranslation();
  const classes = useStyles({
    isCurrentPathLight,
    menuOpen,
  });

  useEffect(() => {
    preventBodyScroll(menuOpen);
  }, [menuOpen]);

  return (
    <>
      <AppBar
        className={classes.appBar}
        position="absolute"
      >
        <Toolbar className={classes.toolBar}>
          <Link to="/" className={classes.logo}>
            <Logo width="4.5em" />
          </Link>
          <IconButton
            className={classes.openButton}
            onClick={() =>
              setMenuOpen((prevState) => !prevState)
            }
          >
            <Burger
              menuOpen={menuOpen}
              isCurrentPathLight={isCurrentPathLight}
            />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Fade
        in={menuOpen}
        timeout={{
          enter: 300,
          exit: 200,
        }}
      >
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          className={classes.drawer}
        >
          <List disablePadding className={classes.list}>
            {navItems.map(({ pathname, path }, index) => {
              return (
                <Slide
                  key={path}
                  direction="right"
                  in={menuOpen}
                  style={{
                    transitionDelay: `${index * 20}ms`,
                  }}
                  timeout={{
                    enter: 200 + index * 50,
                    exit: 0,
                  }}
                >
                  <ListItem className={classes.listItem}>
                    <NavItem
                      path={path}
                      pageName={pathname}
                      closeMenu={() => setMenuOpen(false)}
                    />
                  </ListItem>
                </Slide>
              );
            })}
          </List>
          <Grid item className={classes.bottomPattern} />
        </Grid>
      </Fade>
    </>
  );
};

export default Drawer;
