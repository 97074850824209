import { useEffect, useState } from 'react';
import { useLocation } from '@reach/router';
import { useIntl } from 'gatsby-plugin-intl';
import { string } from 'prop-types';

const useGetCurrentPath = (path) => {
  const [currentPath, setCurrentPath] = useState('');
  const [currentFullPath, setCurrentFullPath] = useState(
    ''
  );
  const [
    currentPathIsActive,
    setCurrentPathIsActive,
  ] = useState(false);
  const [
    isCurrentPathLight,
    setIsCurrentPathLight,
  ] = useState(false);

  const { locale } = useIntl();
  const location = useLocation();
  const { pathname } = location;
  const formattedPath =
    pathname.indexOf(`/${locale}`) === 0
      ? pathname.slice(locale.length + 1)
      : pathname;
  const fullPath = location.href;

  useEffect(() => {
    setCurrentFullPath(fullPath);
  }, [fullPath]);

  useEffect(() => {
    setCurrentPath(formattedPath);
  }, [formattedPath]);

  useEffect(() => {
    setCurrentPathIsActive(currentPath === path);
  }, [currentPath, path]);

  const lightPaths = ['/startups', '/startups/'];

  useEffect(() => {
    setIsCurrentPathLight(lightPaths.includes(currentPath));
  }, [currentPath, lightPaths]);

  return {
    currentPath,
    currentPathIsActive,
    isCurrentPathLight,
    currentFullPath,
  };
};

useGetCurrentPath.propTypes = {
  path: string,
};

useGetCurrentPath.defaultProps = {
  path: '',
};

export default useGetCurrentPath;
