import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import { bool } from 'prop-types';

const useStyles = makeStyles(({ palette: { text } }) => ({
  burgerContainer: {
    height: 12,
    width: 25,
    position: 'relative',
  },
  line: {
    position: 'absolute',
    width: '100%',
    content: '""',
    left: 0,
    height: 2,
    backgroundColor: ({ menuOpen, isCurrentPathLight }) =>
      menuOpen || isCurrentPathLight
        ? text.secondary
        : text.primary,
    transition: 'all .3s',
    '&:first-child': {
      top: 0,
    },
    '&:last-child': {
      bottom: 0,
    },
  },
  menuOpenIcon: {
    '&:first-child': {
      transform: 'rotate(45deg)',
      top: 5,
    },
    '&:last-child': {
      transform: 'rotate(-45deg)',
      bottom: 5,
    },
  },
}));

const Burger = ({ menuOpen, isCurrentPathLight }) => {
  const classes = useStyles({
    menuOpen,
    isCurrentPathLight,
  });

  return (
    <Box className={classes.burgerContainer}>
      <Box
        className={`${classes.line}${
          menuOpen ? ' ' + classes.menuOpenIcon : ''
        }`}
      />
      <Box
        className={`${classes.line}${
          menuOpen ? ' ' + classes.menuOpenIcon : ''
        }`}
      />
    </Box>
  );
};

Burger.propTypes = {
  menuOpen: bool,
  isCurrentPathLight: bool,
};

export default Burger;
