import React from 'react';

const Logo = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 160 35"
      fill="currentColor"
      {...props}
    >
      <g>
        <path d="M32.6475 7.45915C28.8603 7.45915 26.4187 9.15093 24.5731 11.6694L28.1682 14.2647C28.9948 12.8613 30.4751 11.554 32.686 11.554C35.7427 11.554 36.5886 12.7652 36.6847 15.6104H31.4748C27.1685 15.6104 23.8234 16.9946 23.8234 21.1664C23.8234 25.1844 26.8609 27.4529 30.802 27.4529C32.9936 27.4529 35.5505 26.4917 36.8001 24.5115V27.0684H40.9526V15.4182C40.9526 10.7274 37.8767 7.45915 32.6475 7.45915ZM31.744 23.4926C29.6485 23.4926 28.6296 22.589 28.6296 21.3202C28.6296 20.109 29.61 18.994 31.744 18.994H36.704V19.1478C36.4156 21.4163 34.6277 23.4926 31.744 23.4926Z" />
        <path d="M50.9187 27.4529C52.3221 27.4529 54.4176 27.4529 54.4176 27.4529V23.3196C54.4176 23.3196 52.7259 23.3196 51.7454 23.3196C50.515 23.3196 49.6115 22.7813 49.6115 20.9357V11.5386H54.4176V7.61679H49.6115V2.70679H44.9975V7.86287L44.9206 11.7847V21.7624C44.9206 25.2613 47.1699 27.4529 50.9187 27.4529Z" />
        <path d="M66.164 27.4529C69.9705 27.4529 72.8388 25.0998 74.2384 20.9165H69.6845C69.0254 22.2995 67.7286 23.2042 66.1255 23.2042C62.8573 23.2042 61.6269 20.4166 61.6269 17.4945C61.6269 14.63 62.8958 11.7078 66.1255 11.7078C67.7229 11.7078 68.9752 12.6104 69.6398 13.9744H74.4306C73.0849 9.59694 69.932 7.45915 66.1255 7.45915C59.8582 7.45915 56.8592 12.4576 56.8592 17.4945C56.8592 22.589 59.8967 27.4529 66.164 27.4529Z" />
        <path d="M77.8911 27.0684H82.6588V17.3407C82.6588 14.1494 84.0814 11.7078 87.0805 11.7078C89.9065 11.7078 90.541 13.7649 90.541 16.1295V27.0684H95.3087V15.5335C95.3087 11.5156 93.2517 7.45915 88.2148 7.45915C86.3307 7.45915 84.1968 8.20891 82.6588 10.0929V2.70679H77.8911V27.0684Z" />
        <path d="M110.708 27.4529C116.033 27.4529 118.936 22.7813 118.936 17.456C118.936 12.1115 116.033 7.45915 110.631 7.45915C108.574 7.45915 106.574 8.13201 105.229 9.78534V2.70679H100.384V27.0684H105.229V25.1459C106.574 26.7993 108.708 27.4529 110.708 27.4529ZM109.535 23.1273C106.305 23.1273 104.806 20.6858 104.806 17.456C104.806 14.2263 106.305 11.7078 109.535 11.7078C112.765 11.7078 114.207 14.2647 114.207 17.456C114.207 20.6473 112.765 23.1273 109.535 23.1273Z" />
        <path d="M130.048 7.45915C126.261 7.45915 123.819 9.15093 121.973 11.6694L125.568 14.2647C125.926 13.6571 126.407 13.0675 127.016 12.5942C127.814 11.9744 128.833 11.554 130.086 11.554C133.143 11.554 133.989 12.7652 134.085 15.6104H128.875C124.569 15.6104 121.224 16.9946 121.224 21.1664C121.224 25.1844 124.261 27.4529 128.202 27.4529C130.394 27.4529 132.951 26.4917 134.2 24.5115V27.0684H138.353V15.4182C138.353 10.7274 135.277 7.45915 130.048 7.45915ZM129.144 23.4926C127.049 23.4926 126.03 22.589 126.03 21.3202C126.03 20.109 127.01 18.994 129.144 18.994H134.104V19.1478C133.816 21.4163 132.028 23.4926 129.144 23.4926Z" />
        <path d="M144.067 34.9429H149.157L160 7.86287H154.848L149.926 21.2048L145.082 7.86287H139.929L147.446 26.5493L144.067 34.9429Z" />
        <path d="M4.9216 18.4557L4.9216 27.0684L6.10352e-05 27.0684L5.9852e-05 -9.87519e-05L4.9216 -9.8967e-05L4.9216 13.5341L9.84314 13.5341L9.84314 18.4557L4.9216 18.4557Z" />
        <path d="M14.7647 13.5341L14.7647 27.0684L19.6862 27.0684L19.6862 -9.87519e-05L14.7647 -9.8967e-05L14.7647 8.61259L9.84314 8.61259L9.84314 13.5341L14.7647 13.5341Z" />
      </g>
    </svg>
  );
};

export default Logo;
