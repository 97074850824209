import { useIntl } from 'gatsby-plugin-intl';
import { useMemo } from 'react';
import pages from '../constants/pages';

const useGetNavigationTranslation = () => {
  const { formatMessage: t, locale } = useIntl();
  const { navItems } = pages;

  return useMemo(() => {
    if (locale && t) {
      return navItems.map(({ intlId, link }) => {
        return {
          path: link,
          pathname: t({ id: intlId }),
        };
      });
    } else return [];
  }, [locale, t, navItems]);
};

export default useGetNavigationTranslation;
