import React from 'react';
import { string, func } from 'prop-types';
import { Box, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { navigate } from 'gatsby-plugin-intl';
import useGetCurrentPath from '../../../hooks/useGetCurrentPath';

const useStyles = makeStyles(({ palette: { text } }) => ({
  highlighter: {
    content: '""',
    width: '100%',
    height: 7,
    position: 'absolute',
    left: 0,
    bottom: 3,
    backgroundColor: text.secondary,
    opacity: 0.2,
  },
}));

const NavItem = ({ pageName, path, closeMenu }) => {
  const { currentPathIsActive } = useGetCurrentPath(path);
  const classes = useStyles();

  const handleNavigation = (path) => {
    navigate(path);
    closeMenu();
  };

  return (
    <>
      <Link onClick={() => handleNavigation(path)}>
        {pageName}
      </Link>
      {currentPathIsActive && (
        <Box className={classes.highlighter} />
      )}
    </>
  );
};

NavItem.propTypes = {
  pageName: string,
  path: string,
  closeMenu: func,
};

export default NavItem;
