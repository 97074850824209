import React from 'react';
import colors from './colors';
import fonts from './fonts';
import patterns from './patterns';
import {
  ThemeProvider,
  createMuiTheme,
} from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.colorPrimary,
      dark: colors.colorPrimaryDark,
    },
    secondary: {
      main: colors.colorSecondary,
      dark: colors.colorSecondaryDark,
    },
    text: {
      primary: colors.colorTextPrimary,
      secondary: colors.colorTextSecondary,
    },
  },
  patterns: { ...patterns },
  fonts: fonts,
  typography: {
    fontFamily: fonts.family.primary,
    fontSize: fonts.size.xxs,
    button: {
      fontSize: fonts.size.xxs,
    },
    h1: {
      fontSize: fonts.size.xxxxl,
      fontWeight: 700,
      lineHeight: fonts.size.xxxxl + 'px',
    },
    h2: {
      fontSize: fonts.size.xxl,
      fontWeight: 700,
    },
    h4: {
      fontSize: fonts.size.l,
      fontWeight: 700,
    },
    h6: {
      fontSize: fonts.size.xxm,
    },
    subtitle1: {
      fontSize: fonts.size.m,
    },
    subtitle2: {
      fontSize: fonts.size.xs,
      lineHeight: fonts.size.xxm + 'px',
      fontWeight: 700,
    },
    body1: {
      fontSize: fonts.size.xs,
      fontWeight: 500,
    },
    body2: {
      fontSize: fonts.size.xxs,
      fontWeight: 500,
    },
  },
});

export default ({ element }) => (
  <ThemeProvider theme={theme}>{element}</ThemeProvider>
);
