import React from 'react';
import { string } from 'prop-types';
import { Fade, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Link } from 'gatsby-plugin-intl';
import useGetCurrentPath from '../../../hooks/useGetCurrentPath';

const useStyles = makeStyles(
  ({ palette: { primary, text } }) => ({
    highlighter: {
      content: '""',
      width: '100%',
      height: 1,
      position: 'absolute',
      left: 0,
      bottom: -3,
      transition: 'color .3s',
      backgroundColor: ({ isCurrentPathLight }) =>
        isCurrentPathLight ? text.secondary : primary.dark,
    },
  })
);

const NavItem = ({ pageName, path }) => {
  const {
    currentPathIsActive,
    isCurrentPathLight,
  } = useGetCurrentPath(path);

  const classes = useStyles({
    isCurrentPathLight,
  });

  return (
    <>
      <Link to={path}>{pageName}</Link>
      <Fade
        in={currentPathIsActive}
        timeout={{
          enter: 300,
          exit: 0,
        }}
      >
        <Box className={classes.highlighter} />
      </Fade>
    </>
  );
};

NavItem.propTypes = {
  pageName: string,
  path: string,
};

export default NavItem;
