import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import Instagram from '../svg/Instagram';
import LinkedIn from '../svg/LinkedIn';
import Facebook from '../svg/Facebook';
import Youtube from '../svg/Youtube';
import ExternalLink from './ExternalLink';
import { useStaticQuery, graphql } from 'gatsby';

const useStyles = makeStyles(
  ({ breakpoints: { down }, spacing }) => ({
    SocialMediaLinks: {
      opacity: 0.6,
      '& > a': {
        color: 'inherit',
        marginRight: spacing(4),
        [down('xs')]: {
          marginRight: spacing(5),
        },
        '&:last-child': {
          marginRight: spacing(0),
        },
      },
      [down('xs')]: {
        margin: spacing(5, 0),
      },
    },
  })
);
const SocialMediaLinks = () => {
  const classes = useStyles();
  const {
    instagram,
    linkedIn,
    facebook,
    youtube,
  } = useStaticQuery(query).site.siteMetadata.socialMedia;

  const items = [
    {
      link: instagram,
      icon: <Instagram />,
    },
    {
      link: linkedIn,
      icon: <LinkedIn />,
    },
    {
      link: facebook,
      icon: <Facebook />,
    },
    {
      link: youtube,
      icon: <Youtube />,
    },
  ];

  return (
    <Grid item className={classes.SocialMediaLinks}>
      {items.map(({ link, icon }) => (
        <ExternalLink key={link} href={link}>
          {icon}
        </ExternalLink>
      ))}
    </Grid>
  );
};

export default SocialMediaLinks;

const query = graphql`
  query SocialMediaLinks {
    site {
      siteMetadata {
        socialMedia {
          facebook
          instagram
          linkedIn
          youtube
        }
      }
    }
  }
`;
