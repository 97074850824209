export default {
  size: {
    xxxxs: 12,
    xxxs: 14,
    xxs: 16,
    xs: 18,
    s: 20,
    m: 22,
    xm: 24,
    xxm: 26,
    xxxm: 30,
    xxxxm: 34,
    xxxxxm: 37,
    l: 40,
    xl: 45,
    xxl: 49,
    xxxl: 70,
    xxxxl: 100,
  },
  family: {
    primary: 'HKGrotesk',
  },
};
