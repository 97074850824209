import alternatedSquares from '../images/patterns/alternated-squares.png';
import arch from '../images/patterns/arch.png';
import arrow from '../images/patterns/arrow.png';
import archLight from '../images/patterns/arch-light.png';
export default {
  alternatedSquares,
  arch,
  arrow,
  archLight,
};
export const variants = [
  'alternatedSquares',
  'arch',
  'arrow',
  'archLight',
];
