export const formatNumber = (number) => {
  let result = '';
  for (let i = 0; i < number.length; i++) {
    if (i % 3 === 0) {
      result += ` ${number[i]}`;
    } else {
      result += number[i];
    }
  }
  return result;
};

export const hexToRgb = (hex) =>
  hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => '#' + r + r + g + g + b + b
    )
    .substring(1)
    .match(/.{2}/g)
    .map((x) => parseInt(x, 16));

export const preventBodyScroll = (isLocked) => {
  if (isLocked) {
    document.body.style.position = 'fixed';
    document.body.style.top = `-${window.scrollY}px`;
  } else {
    const scrollY = document.body.style.top;
    document.body.style.position = '';
    document.body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
  }
};

export const isBrowser = () =>
  typeof window !== 'undefined';

export const countWords = (text) => text.split(' ').length;

export const copyTextToClipboard = async (text) => {
  if (isBrowser() && navigator && navigator.clipboard) {
    try {
      await navigator?.clipboard?.writeText(text);
      return Promise.resolve();
    } catch (error) {
      alert(error);
      return Promise.reject();
    }
  }
};

export const preventSubmit = (event) =>
  event.key === 'Enter' && event.preventDefault();

export const scrollToElement = (element) => {
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    });
  }
};
