export default ({
  palette: { text },
  spacing,
  fonts,
  breakpoints: { down },
}) => ({
  navBar: {
    paddingTop: spacing(5),
    position: 'absolute',
    width: '100%',
    left: '50%',
    zIndex: 1000,
    transform: 'translateX(-50%)',
    [down(750)]: {
      display: 'none',
    },
  },
  appBar: {
    position: 'relative',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    height: 'auto',
    transition: 'color .3s',
    fontSize: fonts.size.xxxm,
    color: ({ isCurrentPathLight }) =>
      isCurrentPathLight ? text.secondary : text.primary,
  },
  listItems: {
    display: 'flex',
  },
  listItem: {
    width: 'auto',
    padding: spacing(0),
    marginLeft: spacing(8),
    '&:first-child': {
      marginLeft: spacing(0),
    },
    '& > a': {
      transition: 'color .3s',
      color: ({ isCurrentPathLight }) =>
        isCurrentPathLight ? text.secondary : text.primary,
      textDecoration: 'none',
      fontSize: fonts.size.xxs,
    },
  },
});
