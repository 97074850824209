import React from 'react';
import '../../styles/base.css';
import { element, arrayOf, oneOfType } from 'prop-types';
import Nav from '../nav';
import Footer from '../shared/sections/footer';
import { ParallaxProvider } from 'react-scroll-parallax';

const Layout = ({ children }) => {
  return (
    <>
      <Nav />
      <ParallaxProvider>{children}</ParallaxProvider>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: oneOfType([element, arrayOf(element)])
    .isRequired,
};

export default Layout;
