import React from 'react';
import { Container as OrgContainer } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { string } from 'prop-types';

const useStyles = makeStyles(
  ({ spacing, breakpoints: { down } }) => ({
    Container: {
      padding: spacing(0, 7),
      [down('xs')]: {
        padding: spacing(0, 3.75),
      },
    },
  })
);

const Container = ({ className, ...props }) => {
  const classes = useStyles();
  return (
    <OrgContainer
      maxWidth="lg"
      disableGutters
      className={`${classes.Container} ${
        className ? className : ''
      }`}
      {...props}
    />
  );
};

Container.propTypes = {
  className: string,
};

export default Container;
